import React from "react";
import SVG from "react-inlinesvg";
import * as styles from "./CentralizedPlatform.module.scss";
import OnboardWorkflow from "assets/solutions/onboard-workflow.svg";

import { useWindowSize } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";

export type OnBoardWorkflowProps = {
	style?: React.CSSProperties;
};
export default function OnboardWorkflowV4({ style }: OnBoardWorkflowProps) {
	const { isMobile, isTablet } = useWindowSize();

	const imageClass = classNames({
		[styles.animation]: true,
		[styles.tabletSize]: isTablet,
	});

	if (isMobile) {
		return (
			<StaticImage
				loading="eager"
				src={"../../assets/solutions/onboard-workflow-verticle.svg"}
				alt={"Onboard workflow"}
			/>
		);
	}
	return (
		<SVG src={OnboardWorkflow} className={imageClass} style={style} />
	);
}
